import Vue from 'vue'
import App from './App/App.vue'
import router from './router'
import store from './store'
Vue.config.productionTip = false

// 引入jquery
import $ from "jquery";
//jq-ui
import 'jquery-ui-dist/jquery-ui'

// 引入bootstrap
import 'bootstrap'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
// 引入bootstrap的icon
import './assets/bootstrap-icons/font/bootstrap-icons.css'




// 懒加载
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)

//  引入 anychart
import anychart from 'anychart';
Vue.prototype.$anychart = anychart;

// 引入公共Css 解决被覆盖
// 引入font-awesome
// import Fonts from './assets/css/font-awesome.min.css'
import Fonts from "./fonts.css";
import "./assets/css/main.css";

// 引入公共js
import global from "./assets/js/global.js";
Vue.prototype.$$ = global;


// 国际语言
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n = new VueI18n({
	locale: localStorage.getItem('language') || 'id-US', // 设置默认使用语言
	messages: {
		'id-US': require('./common/app-id.json'),
		'en-US': require('./common/app-en.json')
	}
})

new Vue({
	router,
	store,
	$,
	Fonts,
	i18n,
	render: h => h(App)
}).$mount('#app')

// router.beforeEach((to, from, next) => {
//     /* 路由发生变化修改页面title */
//     if (to.meta.title) {
//         document.title = to.meta.title
//     }
//     // if (to.meta.content) {
//     //     let head = document.getElementsByTagName('head');
//     //     let meta = document.createElement('meta');
//     //     document.querySelector('meta[name="keywords"]').setAttribute('content', to.meta.content.keywords)
//     //     document.querySelector('meta[name="description"]').setAttribute('content', to.meta.content.description)
//     //     meta.content = to.meta.content;
//     //     head[0].appendChild(meta)
//     // }
//     next()
// })