import Vue from 'vue'
import VueRouter from 'vue-router'
import $$ from '../assets/js/global.js'
const urlArr = []; const pathArr = []
const Margin = () =>
	import('@/views/Education/views/Margin/Margin.vue')
const DiscoverMore = () =>
	import('@/views/Education/views/WarrantsBulletin/WarrantsBulletin.vue')
$.ajax({
	type: 'GET',
	url: $$.mibb + 'GetDiscoverList',
	data: { id: '', lang: localStorage.getItem('lang') || 2 },
	dataType: 'json',
	async: false,
	success: (data) => {
		urlArr.unshift(
			{
				path: '/Education/DiscoverMore',
				name: 'DiscoverMore',
				component: DiscoverMore,
				meta: {
					name: 'nav.DM',
					title: '',
					id: data.text[0].id,
					keyword: data.text[0].keyword,
					img: 'https://warrants.maybank2u.com.my/mibbresources/assets/Education 1.jpg',
					description: 'nav.Kf',
					content: {
						keywords: '',
						description: ''
					}
				}
			})
	},
	error: function (error) {
		console.log(error)
	}
})

$.ajax({
	type: 'GET',
	url: $$.mibb + 'GetEducationContent',
	data: { id: '', lang: localStorage.getItem('lang') || 2 },
	dataType: 'json',
	async: false,
	success: (data) => {
		$.each(data.text, (i, c) => {
			if (c.title) {
				urlArr.push({
					component: Margin,
					name: c.title.replace(/\s*/g, ''),
					path: '/Education/' + c.title.replace(/\s*/g, ''),
					meta: {
						// 判断是否有三级导航栏
						ViewsNav: false,
						id: c.id,
						name: c.title,
						title: c.title + ' -  Edukasi | Maybank Sekuritas Warrants',
						// img: require(`../../public/image/EducationIndex_${i + 3}.jpg`),
						img: c.image ? c.image : 'https://warrants.maybank2u.com.my/mibbresources/assets/Education 1.jpg',
						content: {}
					}
				})
			}
		})
	},
	error: function (error) {
		console.log(error)
	}
})



// LandingPage
const Home = () =>
	import('../views/Home/Home.vue')

// Warrant tools
const WarrantTools = () =>
	import('@/views/WarrantTools/WarrantTools.vue')
// Views
const LiveMatrix = () =>
	import('@/views/WarrantTools/views/LiveMatrix/LiveMatrix.vue')
const WarrantSearch = () =>
	import('@/views/WarrantTools/views/WarrantSearch/WarrantSearch.vue')
const WarrantTerms = () =>
	import('@/views/WarrantTools/views/WarrantTerms/WarrantTerms.vue')
const WarrantComparison = () =>
	import('@/views/WarrantTools/views/WarrantComparison/WarrantComparison.vue')
const WarrantCalculator = () =>
	import('@/views/WarrantTools/views/WarrantCalculator/WarrantCalculator.vue')
const StructureYourWarrant = () =>
	import('@/views/WarrantTools/views/StructureYourWarrant/StructureYourWarrant.vue')
const Underlying = () =>
	import('@/views/WarrantTools/views/Underlying/Underlying.vue')

// Observations
const Observations = () =>
	import('@/views/Observations/Observations.vue')
// Views
const TopTradedShares = () =>
	import('@/views/Observations/views/TopTradedShares/TopTradedShares.vue')
const WarrantMovers = () =>
	import('@/views/Observations/views/WarrantMovers/WarrantMovers.vue')
const DailyHighlights = () =>
	import('@/views/Observations/views/DailyHighlights/DailyHighlights.vue')
const WarrantWatch = () =>
	import('@/views/Observations/views/WarrantWatch/WarrantWatch.vue')
const WarrantsBulletin = () =>
	import('@/views/Observations/views/WarrantsBulletin/WarrantsBulletin.vue')
const ExpiredWarrant = () =>
	import('@/views/Observations/views/ExpiredWarrant/ExpiredWarrant.vue')

// Warrants Documentations
const Documents = () =>
	import('@/views/Documents/Documents.vue')
// Views
const BaseProspectus = () =>
	import('@/views/Documents/views/BaseProspectus/BaseProspectus.vue')
const TermSheet = () =>
	import('@/views/Documents/views/TermSheet/TermSheet.vue')
const WarrantsAnnouncement = () =>
	import('@/views/Documents/views/WarrantsAnnouncement/WarrantsAnnouncement.vue')
const UnderlyingReports = () =>
	import('@/views/Documents/views/UnderlyingReports/UnderlyingReports.vue')
const PurchaseOrderForm = () =>
	import('@/views/Documents/views/PurchaseOrderForm/PurchaseOrderForm.vue')

// Education
const Education = () =>
	import('@/views/Education/Education.vue')
const EducationIndex = () =>
	import('@/views/Education/views/Index/index.vue')
// Views
const Glossary = () =>
	import('@/views/Education/views/Glossary/Glossary.vue')
const DecksAndSlides = () =>
	import('@/views/Education/views/DecksAndSlides/DecksAndSlides.vue')
const WarrantsGuide = () =>
	import('../views/Education/views/WarrantsGuide/WarrantsGuide.vue')
const Indonesia = () =>
	import('@/views/Education/views/Indonesia/Indonesia.vue')
const WatchOurSeminars = () =>
	import('@/views/Education/views/WatchOurSeminars/WatchOurSeminars.vue')


// About Maybank Investment Bank
const AboutUs = () =>
	import('@/views/AboutUs/AboutUs.vue')
// Views
const SonAboutUs = () =>
	import('@/views/AboutUs/views/SonAboutUs/SonAboutUs.vue')
const ContactUs = () =>
	import('@/views/AboutUs/views/ContactUs/ContactUs.vue')
const Disclaimer = () =>
	import('@/views/AboutUs/views/Disclaimer/Disclaimer.vue')

Vue.use(VueRouter)

const routes = [
	// 路由重定向
	{
		path: '/',
		redirect: '/Home',
		hidden: true,
		meta: {
			title: localStorage.getItem('lang') <= 1 ? 'Home | Maybank Sekuritas Warrants' : 'Home | Maybank Sekuritas Warrants',
			content: {
				keywords: localStorage.getItem('lang') <= 1 ? 'Malaysia Warrants, Maybank Structured Warrants, Malaysia Top Issuer, Malaysia Top Warrants, Structured Warrants, Daily Highlights.' : 'Waran, Waran Terstruktur Maybank Sekuritas, Top Issuer, Top Warrants, Waran Terstruktur, Sorotan Harian.',
				description: localStorage.getItem('lang') <= 1 ? 'Maybank Sekuritas Warrants – The only warrants website you need. Conduct analysis with Warrant Tools. Get information and market news on any issued warrants in Indonesia or simply contact the Maybank Sekuritas Warrants Team for any questions.' : 'Waran Maybank Sekuritas – Satu-satunya website waran yang Anda butuhkan. Lakukan analisis melalui Alat Waran. Dapatkan informasi dan berita pasar tentang setiap waran yang diterbitkan di Indonesia atau hubungi Tim Waran Maybank Sekuritas untuk pertanyaan apapun.'
			}
		}
	},
	// LandingPage
	{
		path: '/Home',
		name: 'Home',
		component: Home,
		meta: {
			name: 'nav.H',
			title: localStorage.getItem('lang') <= 1 ? 'Home | Maybank Sekuritas Warrants' : 'Home | Maybank Sekuritas Warrants',
			content: {
				keywords: localStorage.getItem('lang') <= 1 ? 'Malaysia Warrants, Maybank Structured Warrants, Malaysia Top Issuer, Malaysia Top Warrants, Structured Warrants, Daily Highlights.' : 'Waran, Waran Terstruktur Maybank Sekuritas, Top Issuer, Top Warrants, Waran Terstruktur, Sorotan Harian.',
				description: localStorage.getItem('lang') <= 1 ? 'Maybank Sekuritas Warrants – The only warrants website you need. Conduct analysis with Warrant Tools. Get information and market news on any issued warrants in Indonesia or simply contact the Maybank Sekuritas Warrants Team for any questions.' : 'Waran Maybank Sekuritas – Satu-satunya website waran yang Anda butuhkan. Lakukan analisis melalui Alat Waran. Dapatkan informasi dan berita pasar tentang setiap waran yang diterbitkan di Indonesia atau hubungi Tim Waran Maybank Sekuritas untuk pertanyaan apapun.'
			}
		}
	},

	// Warrant tools
	{
		path: '/WarrantTools',
		name: 'Warrant tools',
		component: WarrantTools,
		meta: {
			name: 'nav.WT'
		},
		// Views
		children: [
			{
				path: '/WarrantTools/LiveMatrix',
				name: 'Live Matrix',
				component: LiveMatrix,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.LM',
					titleEn: 'Live Matrix - Warrant Tools | Maybank Warrants',
					titleId: 'Matriks Live - Alat Waran | Maybank Warrants',
					content: {
						keywords: 'Live Matrix, Bid Price,',
						description: 'Review the indicative bid and offer prices for warrants listed by Maybank IB, based on various price points in the underlying (stock or index).'
					}
				}
			},
			{
				path: '/WarrantTools/WarrantSearch',
				name: 'Warrant Search',
				component: WarrantSearch,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.WS',
					title: 'Temukan Waran - Alat Waran | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Pencarian Waran, Tren Waran, Waran Baru, Waran Sensitif, Likuiditas Tinggi',
						description: 'Cari waran pilihan Anda dengan mudah'
					}
				}
			}, {
				path: '/WarrantTools/WarrantTerms',
				name: 'Warrant Terms',
				component: WarrantTerms,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.WTe',
					title: 'Detail Waran - Alat Waran | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Persyaratan Waran, Ketentuan Waran, Grafik Waran',
						description: 'Bandingkan Ketentuan Waran dari beberapa waran secara bersamaan dengan menggunakan indikator utamanya untuk setiap waran yang tercatat di Bursa Efek Indonesia (BEI).'
					}
				}
			}, {
				path: '/WarrantTools/WarrantComparison',
				name: 'Warrant Comparison',
				component: WarrantComparison,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.WC',
					title: 'Bandingkan Waran - Alat Waran | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Perbandingan Waran',
						description: 'Bandingkan waran dan underlying nya dengan menggunakan faktor kunci yang telah disediakan berdampingan'
					}
				}
			}, {
				path: '/WarrantTools/WarrantCalculator',
				name: 'Warrant Calculator',
				component: WarrantCalculator,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.WCa',
					title: 'Kalkulator Waran - Alat Waran | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Kalkulator Waran, Penyelesaian Waran, Harga Waran',
						description: 'Kalkulator waran memudahkan investor untuk mengukur harga teoritis dari suatu waran yang diminatinya'
					}
				}
			}, {
				path: '/WarrantTools/StructureYourWarrant',
				name: 'Structure Your Warrant',
				component: StructureYourWarrant,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.SYW',
					title: 'Telusuri Waran - Alat Waran | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Saran Waran, Rekomendasi Waran, Maybank Sekuritas Menerbitkan Waran, Menyusun Waran Anda',
						description: 'Mekanisme umpan balik memberikan solusi dan saran terkait waran yang diminati investor'
					}
				}
			},
			{
				path: '/WarrantTools/TechnicalChart',
				name: 'Technical Chart',
				component: Underlying,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.TC',
					title: 'Grafik Teknikal - Alat Waran | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Grafik Teknis, Analisis Teknis',
						description: 'Lakukan analisis teknikal pada grafik underlying untuk menganalisis arah dan performa dari suatu underlying waran'
					}
				}
			}
		]
	},
	// Observations
	{
		path: '/Observations',
		name: 'Observations',
		component: Observations,
		meta: {
			name: 'nav.Ob'
		},
		// Views
		children: [
			{
				path: '/Observations/TopTradedShares',
				name: 'Top Traded Shares',
				component: TopTradedShares,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.TTS',
					title: 'Top Traded Shares - Pantauan | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Saham yang diperdagangkan teratas',
						description: 'Peringkat 10 terbaik berdasarkan volume transaksi, terkait dengan underlying saham dari Waran Terstruktur yang diterbitkan oleh Maybank Sekuritas '
					}
				}
			},
			{
				path: '/Observations/WarrantMovers',
				name: 'Warrant Movers',
				component: WarrantMovers,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.WM',
					title: 'Penggerak Waran - Pantauan | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Top 10 ranking of Indonesian warrants by category.',
						description: 'Peringkat 10 besar waran Indonesia berdasarkan kategori'
					}
				}
			},
			// {
			// 	path: '/Observations/WarrantsWatch',
			// 	name: 'Warrants Watch',
			// 	component: WarrantWatch,
			// 	meta: {
			// 		// 判断是否有三级导航栏
			// 		ViewsNav: false,
			// 		name: 'nav.WW',
			// 		titleEn: 'Warrants Watch - Observations | Maybank Warrants',
			// 		titleId: 'Pengamat Waran – Pengamatan | Maybank Warrants',
			// 		content: {
			// 			keywords: 'Warrant Information, Daily Highlights, Warrant News, Sector Warrants Write Up, Warrant Suggestion, Warrant Recommendation,',
			// 			description: 'Maybank Insights on selected warrants.'
			// 		}
			// 	}
			// },
			// {
			// 	path: 'https://telegram.org/',
			// 	name: 'Telegram Live',
			// 	meta: {
			// 		// 判断是否有三级导航栏
			// 		ViewsNav: false,
			// 		title: 'Telegram Live - Observations | Maybank Warrants',
			// 		content: {}
			// 	}
			// },
			{
				path: '/Observations/WarrantsBulletin',
				name: 'Warrants Bulletin',
				component: WarrantsBulletin,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.WB',
					title: 'Buletin Waran - Pantauan | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Berita dan Update Waran Terstruktur',
						description: 'Informasi singkat tentang update waran, waran baru dan jatuh tempo, serta waran apa saja yang harus diinvestasikan'
					}
				}
			},
			{
				path: '/Observations/ExpiredWarrant',
				name: 'Expired Warrant',
				component: ExpiredWarrant,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'hp.EW',
					title: 'Waran Berakhir - Pantauan | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Ketahui waran berstruktur oleh Maybank yang bakal berakhir',
						description: 'Ketahui waran berstruktur oleh Maybank yang bakal berakhir'
					}
				}
			}
		]
	},
	// Warrants Documentations
	{
		path: '/Documents',
		name: 'Documents',
		component: Documents,
		meta: {
			name: 'nav.Do'
		},
		// Views
		children: [{
			path: '/Documents/BaseProspectus',
			name: 'Base Prospectus',
			component: BaseProspectus,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.BP',
				title: 'Prospektus - Lampiran | Maybank Sekuritas Warrants',
				content: {
					keywords: 'Prospektus Waran Maybank Sekuritas',
					description: 'Disarankan agar Anda meninjau dokumen-dokumen ini sebelum berinvestasi dalam waran terstruktur'
				}
			}
		},
		{
			path: '/Documents/TermSheet',
			name: 'Term Sheet',
			component: TermSheet,
			meta: {
				// 判断是否有三级导航栏
				ViewsNav: false,
				name: 'nav.TS',
				title: 'Lembar Ketentuan - Lampiran | Maybank Sekuritas Warrants',
				content: {
					keywords: 'Lembar Ketentuan Waran Maybank Sekuritas, Dokumen Waran Maybank Sekuritas',
					description: 'Disarankan agar Anda meninjau dokumen-dokumen ini sebelum berinvestasi dalam waran terstruktur'
				}
			}
		},
		{
			path: '/Documents/WarrantsAnnouncement',
			name: 'Warrants Announcement',
			component: WarrantsAnnouncement,
			meta: {
				name: 'nav.WA',
				title: 'Pengumuman Waran Terstruktur - Lampiran | Maybank Sekuritas Warrants',
				content: {
					keywords: 'Pengumuman Waran',
					description: 'Pengumuman terkait penerbitan waran Maybank Sekuritas termasuk penyesuaian aksi korporasi'
				}
			}
		},

		// {
		//   path: '/Documents/UnderlyingReports',
		//   name: 'Underlying Reports',
		//   component: UnderlyingReports,
		//   meta: {
		//     // 判断是否有三级导航栏
		//     ViewsNav: false,
		//     title: '',
		//     content: {}
		//   }
		// }
		{
			path: '/Documents/PurchaseOrderForm',
			name: 'Purchase Order Form',
			component: PurchaseOrderForm,
			meta: {
				name: 'nav.SWs',
				title: 'Formulir Pemesanan',
				content: {
					keywords: 'Penawaran Umum Perdana, Penawaran Umum',
					description: 'Jadilah yang pertama membeli waran yang diterbitkan Maybank Sekuritas selama periode penawaran umum'
				}
			}
		}

		]
	},
	// Education
	{
		path: '/Education',
		name: 'Education',
		component: Education,
		meta: {
			name: 'nav.Ed'
		},
		// Views
		children: [
			{
				path: '/Education/Education',
				name: 'EducationIndex',
				component: EducationIndex,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					title: 'Edukasi | Maybank Sekuritas Warrants',
					content: {
						keywords: 'Video Waran, Infografis Waran',
						description: 'Tingkatkan pengetahuan waran terstruktur senyaman Anda berada dirumah'
					}
				}
			},
			// Indicators
			{
				path: '/Education/WarrantsGuide',
				name: 'WarrantsGuide',
				component: WarrantsGuide,
				meta: {
					name: 'nav.WG',
					title: 'Petunjuk Waran - Edukasi | WarrantsGuide',
					img: require('../../public/image/EducationIndex_1.jpg'),
					description: 'nav.WGT',
					content: {
						keywords: 'Infografis Waran',
						description: 'Tingkatkan pengetahuan waran terstruktur senyaman Anda berada dirumah'
					}
				}
			},
			{
				path: '/Education/Glossary',
				name: 'Glossary',
				component: Glossary,
				meta: {
					// 判断是否有三级导航栏
					ViewsNav: false,
					name: 'nav.Gl',
					title: 'Glosarium - Edukasi | Maybank Sekuritas Warrants',
					img: require('../../public/image/EducationIndex_2.png'),
					description: 'Gly.Sitywt',
					content: {
						keywords: 'Terminologi Waran, Istilah Waran',
						description: 'Kumpulan terminologi investasi yang mungkin Anda temui dalam perdagangan waran'
					}
				}
			},
			// {
			// 	path: '/Education/DecksAndSlides',
			// 	name: 'Decks and Slides',
			// 	component: DecksAndSlides,
			// 	meta: {
			// 		// 判断是否有三级导航栏
			// 		ViewsNav: false,
			// 		name: 'nav.DaS',
			// 		titleEn: 'Decks and Slides - Education | Maybank Warrants',
			// 		titleId: 'Dek dan Slide – Edukasi | Maybank Warrants',
			// 		img: require('../../public/image/EducationIndex_3.png'),
			// 		description: 'DASs.DweWW',
			// 		content: {
			// 			keywords: 'Maybank Webinar Decks, Maybank Webinar Presentation',
			// 			description: 'Download decks from webinars or information relating to Warrants Trading and the Maybank Warrants Website'
			// 		}
			// 	}
			// },
			{
				path: '/Education/Infographics',
				name: 'Infographics',
				component: Indonesia,
				meta: {
					name: 'nav.Ia',
					title: '',
					img: require('../../public/image/Infographics_img.jpg'),
					description: 'SWS.TitleI',
					content: {
						keywords: '',
						description: ''
					}
				}
			},

			...urlArr

			// {
			// 	path: '/Education/WatchOurSeminars',
			// 	name: 'Watch Our Seminars',
			// 	component: WatchOurSeminars,
			// 	meta: {
			// 		// 判断是否有三级导航栏
			// 		ViewsNav: false,
			// 		title: '',
			// 		content: {}
			// 	}
			// }
		]
	},
	// About Maybank Investment Bank
	{
		path: '/AboutUs',
		name: 'About Us',
		component: AboutUs,
		meta: {
			name: 'nav.AU'
		},
		// Views
		children: [{
			path: '/AboutUs/AboutUs',
			name: 'About Us',
			component: SonAboutUs,
			meta: {
				name: 'nav.AU',
				title: 'Tentang Kami - Tentang Kami | Maybank Sekuritas Warrants',
				content: {
					keywords: 'Waran Terstruktur Maybank Sekuritas',
					description: 'Sejarah dan Latar belakang Maybank Sekuritas'
				}
			}
		}, {
			path: '/AboutUs/ContactUs',
			name: 'Contact Us',
			component: ContactUs,
			meta: {
				name: 'nav.CU',
				title: 'Hubungi Kami - Tentang Kami | Maybank Sekuritas Warrants',
				content: {
					keywords: 'Help Desk Waran Maybank Sekuritas, Waran Terstruktur Maybank Sekuritas',
					description: 'Hubungi tim Waran Maybank Sekuritas'
				}
			}
		}, {
			path: '/AboutUs/Disclaimer',
			name: 'Disclaimer',
			component: Disclaimer,
			meta: {
				name: 'nav.Di',
				title: 'Disclaimer - Tentang Kami | Maybank Sekuritas Warrants',
				content: {
					keywords: '',
					description: 'Syarat dan Ketentuan yang berlaku saat menggunakan Website Waran Maybank Sekuritas'
				}
			}
		}

		]
	}
]

// 解决重复触发了同一个路由
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
	return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
